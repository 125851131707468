'use client';
import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/src/lib/utils';
import { GtmEvent, trackGtmEvent } from '@/src/helpers/analytics.ts';

import { withTooltips } from '@/src/components/ui/hoc/withTooltips.tsx';

const buttonVariants = cva(
  'button inline-flex items-center justify-center rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-background/90 ring-primary text-foreground hover:bg-background/75',
        primary:
          'bg-primary ring-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background text-foreground hover:bg-foreground/5 hover:text-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        'card-odd': 'bg-muted/25 hover:bg-muted text-card-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        card: 'bg-card hover:bg-muted text-card-foreground',
        fancy:
          '[&:not(:hover)]:bg-gradient-to-r from-secondary/80 via-secondary/70 to-secondary animate-bg text-secondary-foreground hover:bg-neutral-400 hover:text-accent-foreground',
        colorful:
          '[&:not(:hover)]:bg-gradient-to-r from-pink-300 to-violet-300 text-white hover:bg-accent hover:text-accent-foreground',
      },
      size: {
        inline: '',
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'rounded-md text-lg px-5 py-4',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  arrow?: boolean;
  clickTrackGtmEvent?: GtmEvent;
}

const InternButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      arrow = false,
      asChild = false,
      clickTrackGtmEvent,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const onClick = () => {
      if (clickTrackGtmEvent) {
        trackGtmEvent(clickTrackGtmEvent);
      }
    };

    return (
      <Comp
        className={cn('group', buttonVariants({ variant, size, className }))}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        <Slottable>{children}</Slottable>
        {arrow && (
          <span className="ml-2 !transition-transform duration-500 group-hover:translate-x-1 group-disabled:translate-x-0">
            &raquo;
          </span>
        )}
      </Comp>
    );
  },
);
InternButton.displayName = 'Button';

const Button = withTooltips(InternButton);

export { Button, buttonVariants };
